<template>
  <div class="page">
    <div class="formBox">
      <a-form :form="form" @submit="handleSearch">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item label="商品名称">
              <a-input-search placeholder="搜索商品" style="width: 220px" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="上架时间">
              <a-range-picker :placeholder="['开始时间', '结束时间']">
              </a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="商品价格">
              <div class="search__price">
                <a-input placeholder="输入价格" style="width: 220px" />
                <span>至</span>
                <a-input placeholder="输入价格" style="width: 220px" />
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item>
              <a-button
                type="primary"
                html-type="submit"
                style="margin-right: 10px"
              >
                查询
              </a-button>
              <a-button type="primary" style="margin-right: 10px">
                重置
              </a-button>
              <!-- <a-button type="primary" style="margin-right: 10px">
                导出商品
              </a-button> -->
              <a-button type="primary"> 添加商品 </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="page__table">
      <a-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="pagination"
        bordered
        @change="changeTable"
      >
      <div slot="goods_name" slot-scope="text, record" class="goods_name_box">
          <img :src="record.picture_url" alt="" class="list__img" />
          <span class="goods_name" style="flex:1;">{{ record.goods_name }}</span>
        </div>
        <template slot="kc" slot-scope="text, record">
          <editable-cell
            :text="text"
            @change="onCellChange(record, $event)"
          />
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "商品名称",
     dataIndex: "goods_name",
    key: "goods_name",
    scopedSlots: { customRender: "goods_name" },
    width: '20%',
    align: 'center'
  },
  {
    title: "货号/规格编码",
    dataIndex: "name",
    key: "name",
    align: 'center'
  },
  {
    title: "店铺价格",
    dataIndex: "price",
    key: "price",
    align: 'center'
  },
  {
    title: "成本价格",
    dataIndex: "cost_price",
    key: "cost_price",
    align: 'center'
  },
  {
    title: "市场价格",
    dataIndex: "market_price",
    key: "market_price",
    align: 'center'
  },
  {
    title: "分类名称",
    dataIndex: "category_name",
    key: "category_name",
    align: 'center'
  },
  {
    title: "库存",
    dataIndex: "quantity",
    scopedSlots: { customRender: "kc" },
    sorter: (a, b) => a.quantity - b.quantity,
    align: 'center'
  },
  {
    title: "重量",
    dataIndex: "weight",
    key: "weight",
    sorter: (a, b) => a.weight - b.weight,
    align: 'center'
  },
  {
    title: "库存下限",
    dataIndex: "inventory_lower",
    key: "inventory_lower",
    align: 'center'
  },
  {
    title: "库存预警",
    dataIndex: "open_inventory_warnning",
    key: "open_inventory_warnning",
    align: 'center'
  },
  // {
  //   title: "操作",
  //   key: "action",
  //   scopedSlots: { customRender: "action" },
  // },
];
export default {
  name: "GoodsSoldOut",
  components: {
    EditableCell: () => import('./EditableCell')
  },
  data() {
    return {
      dataSource: [],
      columns,
      page: 1,
      pagination: {
        total: 0,
        showTotal: (total, range) => {
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
        defaultPageSize: 15,
      },
      form: this.$form.createForm(this, { name: "advanced_search" }),
    };
  },
  computed: {
    params () {
      return {
        is_active: this.is_active,
        page: this.page,
        page_size: 15,
        is_sell_out: true
      }
    },
  },
  created() {
    this.initTable();
  },
  methods: {
    changeTable(pagination) {
      console.log(pagination, 'pagination---')
      this.page = pagination.current;
      this.initTable();
    },
    initTable() {
      this.$axios.get("/goods_specs/", {params: this.params}).then((res) => {
        this.dataSource = res.results || [];
        this.pagination.total = res.count || 0;
      });
    },
    onCellChange(record, value) {
      this.$axios.post(`/goods_specs/${record.id}/set_inventory/`, {
        quantity: value
      })
      

    },
  },
};
</script>
<style lang="less" scoped>
.search__price {
  display: flex;
  align-items: center;
  > span {
    margin: 0 10px;
  }
}
.action__btn {
  font-size: 20px;
  color: #1890ff;
  > i {
    cursor: pointer;
    &:first-child {
      margin-right: 10px;
    }
  }
}
.list__img {
  display: inline-block;
  width: 100px;
  height: 100px;
  margin-right: 10px;
}
.goods_name {
  display: inline-block;
  // width: 100px;
}
.goods_name_box {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
</style>